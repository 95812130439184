<template>
	<CarbarButton
		v-bind="$attrs"
		class="group w-full"
		:class="[]"
		:is-outline="isOutline"
		:is-mixed="isMixed"
		:is-full-width="isFullWidth"
		:fixed-height="true"
		:size="size"
		:border="border"
		:border-color="borderColor"
		:background-color="[isOutline ? 'bg-transparent' : backgroundColor]"
		:text-color="[isOutline ? textColor : 'text-white']"
		:font-size="fontSize"
		:rounded="rounded"
		@click="onClick"
	>
		<div class="flex items-center justify-center align-middle">
			<div
				class="flex align-middle"
				:class="[iconOnLeft ? iconOnLeftSpace : iconOnRightSpace]"
			>
				<slot name="icon">
					<IconTimes class="m-auto" :class="[iconClass]" />
				</slot>
			</div>
			<div class="flex whitespace-normal text-left">
				<slot>Button</slot>
			</div>
		</div>
	</CarbarButton>
</template>

<script setup>
import CarbarButton from '@/components/buttons/CarbarButton.vue'
import IconTimes from '@/assets/svg/common/ico-times.svg'

const props = defineProps({
	isOutline: {
		type: Boolean,
		default: true,
		required: false,
	},
	isMixed: {
		type: Boolean,
		default: false,
		required: false,
	},
	isFullWidth: {
		type: Boolean,
		default: false,
		required: false,
	},
	iconOnLeft: {
		type: Boolean,
		default: true,
		required: false,
	},
	iconWidth: {
		type: String,
		required: false,
		default: 'w-3',
	},
	iconHeight: {
		type: String,
		required: false,
		default: 'h-3',
	},
	iconColor: {
		type: String,
		required: false,
		default: 'fill-current',
	},
	iconClass: {
		type: String,
		required: false,
		default: '',
	},
	iconOnLeftSpace: {
		type: String,
		required: false,
		default: 'order-first mr-3',
	},
	iconOnRightSpace: {
		type: String,
		required: false,
		default: 'order-last ml-3',
	},
	// TODO deprecate this. use iconOnLeftSpace instead
	gapX: {
		type: String,
		required: false,
		default: 'space-x-2',
	},
	size: {
		type: Number,
		default: 5,
		required: false,
	},
	backgroundColor: {
		type: String,
		default: 'bg-orange-400 hover:bg-orange-600',
		required: false,
	},
	textColor: {
		type: String,
		default: 'text-gray-600 hover:text-orange-400',
		required: false,
	},
	fontSize: {
		type: String,
		default: 'text-base',
		required: false,
	},
	border: {
		type: String,
		default: 'border',
		required: false,
	},
	borderColor: {
		type: String,
		default: 'border-gray-400',
		required: false,
	},
	shadow: {
		type: String,
		default: '',
		required: false,
	},
	rounded: {
		type: String,
		default: 'rounded',
		required: false,
	},
})

const emit = defineEmits(['click'])

const onClick = () => {
	emit('click')
}
</script>
